<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>学院设置</el-breadcrumb-item>
      <el-breadcrumb-item>视频管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="name">
            <div style="display: inline-block" class="divSpan">视频名称：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入搜索的名称"
                v-model.trim="queryinfo.name"
                clearable
                @clear="getList"
                style="width: 220px"
              >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item prop="productName">
            <div style="display: inline-block" class="divSpan">产品名称：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.productName"
                :options="ProductStatus"
                :optionKey="ProductStatuskeys"
                Splaceholder="请选择搜索的产品"
                @clear="getList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>
          <el-form-item prop="videoLabel">
            <div style="display: inline-block" class="divSpan">视频分类：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.videoLabel"
                :options="videoStatus"
                :optionKey="ProductStatuskeys"
                Splaceholder="请选择搜索的类别"
                @clear="getList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
        </el-form>
      </el-row>
      <el-row :gutter="30" style="text-align: right; margin-right: 40px">
        <el-button type="primary" @click="addVideo">新增视频</el-button>
        <el-button type="primary" @click="setVideoLabel">视频分类</el-button>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="VideoList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="name"
            label="视频名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="subject"
            label="视频内容"
            min-width="150px"
          ></el-table-column>

          <el-table-column
            prop="userId"
            label="视频时长"
            min-width="100px"
          ></el-table-column>

          <el-table-column
            prop="userId"
            label="视频分类"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="payTime"
            label="更新时间"
            min-width="150px"
          ></el-table-column>

          <el-table-column label="操作" min-width="150px" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" type="primary">上移</el-button>
              <el-button size="mini" type="primary">下移</el-button>
              <el-button size="mini" type="primary">启用</el-button>
              <el-button
                size="mini"
                type="danger"
                @click="enableVideo(scope.row)"
                >禁用</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 新增视频 -->
    <el-dialog
      :close-on-click-modal="false"
      title="新增视频"
      :visible.sync="addDialog"
      width="30%"
      @close="createDialogClosed"
    >
      <el-form
        :model="createForm"
        :rules="createFormRules"
        ref="createFormRef"
        label-width="100px"
        v-loading="loading"
        element-loading-text="资源正在上传中，请稍等"
      >
        <el-form-item label="视频名称：" prop="name">
          <el-input
            v-model.trim="createForm.name"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属产品：" prop="product">
          <ELselect
            v-model="createForm.product"
            :options="ProductStatus"
            :optionKey="ProductStatuskeys"
            Splaceholder="请选择"
            @change="changeType"
          ></ELselect>
        </el-form-item>
        <el-form-item label="视频分类：" prop="type">
          <ELselect
            v-model="createForm.type"
            :options="videoStatus"
            :optionKey="ProductStatuskeys"
            Splaceholder="请选择"
            @change="changeType"
          ></ELselect>
        </el-form-item>
        <el-form-item label="视频内容：" prop="describe">
          <el-input
            type="textarea"
            clearable
            rows="4"
            placeholder="请输入视频描述"
            v-model.trim="createForm.describe"
            maxlength="200"
            @input="DesText"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="视频文件：" prop="filename">
          <div style="display: inline-block; float: left; width: 75%">
            <el-input
              v-model.trim="createForm.filename"
              placeholder="请上传"
              :readonly="true"
            ></el-input>
          </div>
          <div style="display: inline-block"></div>
        </el-form-item>

        <el-form-item label="视频界面：" prop="Icons"> </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取消</el-button>
        <el-button type="primary" @click="confirmAdd">确定</el-button>
      </span>
    </el-dialog>

    <!--  视频分类-->
    <el-dialog
      :close-on-click-modal="false"
      title="新增视频分类"
      :visible.sync="setDialog"
    >
      <el-button type="primary" style="display: flex" @click="addLabels"
        >新增分类</el-button
      >

      <el-row
        :gutter="30"
        style="display: flex; margin-right: 1px; margin-left: 1px"
      >
        <el-table
          :data="setLabelList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          :cell-style="{
            textAlign: 'center',
          }"
          tooltip-effect="dark"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column
            prop="name"
            label="类别"
            align="center"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column label="操作" min-width="150px">
            <template slot-scope="scope">
              <el-button size="mini" @click="Edits(scope.row)">修改</el-button>
              <el-button size="mini" @click="deletes(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="新增分类"
      :visible.sync="setLabelDialog"
      width="30%"
      @close="createLabelDialogClosed"
    >
      <el-form
        :model="createLabel"
        :rules="createLabelFormRules"
        ref="createLabelFormRef"
        label-width="100px"
      >
        <el-form-item label="类别名称：" prop="name">
          <el-input
            v-model.trim="createLabel.name"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="setLabelDialog = false">取消</el-button>
        <el-button type="primary" @click="LabelAdd">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ELselect from '@/components/myself/el_select'

export default {
  components: { ELselect },
  data () {
    return {
      queryinfo: {
        name: '',
        productName: '',
        videoLabel: '',
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      VideoList: [],

      ProductStatus: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 0,
          label: '低代码平台'
        },

        {
          value: 2,
          label: '大数据平台'
        }],
      ProductStatuskeys: {
        value: 'value',
        label: 'label',
        label2: ''
      },
      videoStatus: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 0,
          label: '入门系列'
        },
        {
          value: 2,
          label: '高级进阶'
        }],

      addDialog: false,
      setDialog: false,
      createForm: {
        name: '',
        product: '',
        type: '',
        describe: '',
        Icons: '',
        Iconsize: '',
        filename: ''
      },
      createFormRules: {
        type: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
          { min: 1, max: 50, message: '长度需在1-50个字符间', trigger: 'blur' }
        ],
        product: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
        describe: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
        Icons: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        filename: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
      },
      wordnum: 0,
      KindID: '',
      loading: false,
      acceptFile: '.mp4',
      setLabelList: [],
      setLabelDialog: false,
      createLabel: {
        name: ''
      },
      createLabelFormRules: {

        name: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
          { min: 1, max: 50, message: '长度需在1-50个字符间', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () { },
    enableVideo () { },

    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getList()
    },
    dateSerach () {
      this.getList()
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },

    addVideo () {
      this.addDialog = true
    },
    createDialogClosed () {
      this.$refs.createFormRef.resetFields()
    },
    setVideoLabel () {
      this.setDialog = true
    },


    DesText () {
      this.wordnum = this.createForm.describe.length
    },
    changeType (para) {
      this.KindID = para
    },
    confirmAdd () { },

    beforeUpload1 () { },



    addLabels () {
      this.setLabelDialog = true
    },
    createLabelDialogClosed () {
      this.$refs.createLabelFormRef.resetFields()
    },
    LabelAdd () { },
    Edits () { },
    deletes () { }
  }
}
</script>
<style lang="less" scoped>
.divSpan {
  font-size: 16px;
}

/deep/ .el-form-item__content {
  margin-right: 30px;
}
</style>